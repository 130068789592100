<template>
  <div class="record">
    <div class="df-row">
      <div class="df-col">
        <el-select
          v-model="submitData.status"
          size="mini"
          placeholder="订单状态"
          clearable
        >
          <el-option
            v-for="item in statusList"
            :key="item.Value"
            :label="item.Name"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearch"
          >查 询</el-button
        >
      </div>
    </div>

    <el-table
      v-loading="loading"
      :data="tableList"
      :max-height="tableMaxHeight"
      border
      stripe
    >
      <el-table-column prop="GrouponId" label="拼单ID" width="100"> </el-table-column>
      <el-table-column prop="" label="商品图片" width="100">
        <template slot-scope="scope">
          <el-image :src="scope.row.ItemImage" fit="fill"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="ItemName" label="商品名称"></el-table-column>
      <el-table-column prop="GrouponPersons" label="成团人数" width="100"></el-table-column>
      <el-table-column prop="ActualPersons" label="已拼人员" width="100"></el-table-column>
      <!-- <el-table-column prop="" label="发起人(团长)"></el-table-column> -->
      <el-table-column prop="" label="拼单结束时间" width="160">
        <template slot-scope="scope">
          {{
            util.dateFormat(
              "YYYY-mm-dd HH:MM:SS",
              new Date(scope.row.EndTime * 1000)
            )
          }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="拼单状态" width="110">
        <template slot-scope="scope">
          <el-tag
            :type="
              scope.row.Status == 1
                ? 'warning'
                : scope.row.Status == 2
                ? 'success'
                : 'danger'
            "
            effect="dark"
            >{{ scope.row.StatusText }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="" label="发起时间" width="160">
        <template slot-scope="scope">
          {{
            util.dateFormat(
              "YYYY-mm-dd HH:MM:SS",
              new Date(scope.row.BeginTime * 1000)
            )
          }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="操作" width="140">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="small"
            @click="onGrouponPerson(scope.row)"
            >拼团成员</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- start 分页器 -->
    <el-pagination
      background
      :page-sizes="[10, 20, 30, 40]"
      :page-size="submitData.page_size"
      :current-page="submitData.page_index"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <!--end 分页器-->

    <GrouponPersonDialog ref="GrouponPersonDialog"></GrouponPersonDialog>
  </div>
</template>

<script>
import Shop from "@/api/shop.js";

import GrouponPersonDialog from "./components/groupon-person-dialog.vue";

export default {
  components: { GrouponPersonDialog },

  data() {
    return {
      totalRecord: 0,
      tableMaxHeight: "",
      submitData: {
        // begin_date: "",
        // end_date: "",
        status: 0,
        page_index: 1,
        page_size: 20,
      },
      statusList: [
        {
          Name: "全部",
          Value: 0,
        },
        {
          Name: "进行中",
          Value: 1,
        },
        {
          Name: "成功",
          Value: 2,
        },
        {
          Name: "失败",
          Value: 3,
        },
      ],
      multipleDate: [],
      tableList: [],
      loading: false,
    };
  },

  created() {
    this.getResize();
  },

  async mounted() {
    window.addEventListener("resize", this.getResize);
    // this.multipleDate = [
    //   this.util.getNowMonthToday(),
    //   this.util.getNowMonthToday(),
    // ];

    // await this.onChangeDate();
    await this.getGrouponRecordList();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取拼单列表
    async getGrouponRecordList() {
      try {
        let { data } = await Shop.getGrouponRecordList(this.submitData);
        this.tableList = data.list;
        this.totalRecord = data.filter.TotalRecord;
      } catch (err) {
        console.log(err);
      }
    },

    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68 + 40);
    },

    // 查询按钮点击事件
    onSearch() {
      this.submitData.page_index = 1;
      this.getGrouponRecordList();
    },

    // 打开拼团成员对话框
    onGrouponPerson(event) {
      this.$refs.GrouponPersonDialog.onShowDialog(event);
    },

    // 日期改变时间
    onChangeDate() {
      this.submitData.begin_date = this.multipleDate[0];
      this.submitData.end_date = this.multipleDate[1];
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.getGrouponRecordList();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getGrouponRecordList();
    },
  },
};
</script>

<style lang="less" scoped>
.record {
  .df-row {
    margin-top: 20px;
  }

  .el-table {
    .el-image {
      width: 60px;
      height: 60px;
    }
  }

  .el-pagination {
    margin-top: 20px;
    float: right;
  }
}
</style>
